:root {
    --primary-color: #381373;
    --secondary-color: #f5671f;
    --text-color: #333;
    --bg-color: #fff;
    --light-gray: #f4f4f4;
    --shadow-color: rgba(0, 0, 0, 0.1);
}

.services {
    padding: 4rem 0;
    background-color: var(--light-gray);
    text-align: center;
}

.services h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 700;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.container a {
    display: inline-block;
    text-decoration: none;
    font-size: 2.7rem;
    font-weight: 700;
    color: var(--secondary-color);
    margin-bottom: 1rem;
    transition: color 0.3s ease;
}

.container a:hover {
    color: var(--primary-color);
}

.services_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.service_card {
    background-color: var(--bg-color);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px var(--shadow-color);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service_card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.service_card h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 1.6rem;
    font-weight: 700;
}

.service_card p {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.service_card p:hover {
    color: var(--secondary-color);
}

/* Responsividade - Cards em pares de dois em telas médias */
@media (max-width: 1024px) {
    .services_grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Responsividade - Um card por linha em telas pequenas */
@media (max-width: 768px) {
    .services_grid {
        grid-template-columns: 1fr;
    }

    .container a {
        font-size: 1.2rem;
    }

    .service_card h3 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .service_card {
        padding: 1.5rem;
    }

    .services h2 {
        font-size: 2rem;
    }

    .service_card h3 {
        font-size: 1.3rem;
    }

    .service_card p {
        font-size: 0.9rem;
    }
}
