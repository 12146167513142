.formContainer {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

/* Estilização das labels principais */
.label {
  display: block;
  margin-bottom: 1.2rem;
  color: #1D0835;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 0.8rem 1rem;
  background: #f8f9fa;
  border-left: 4px solid #381373;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.label:hover {
  background: #f1f3f5;
  transform: translateX(5px);
}

/* Estilização refinada dos checkboxes */
.checkboxGroup {
  margin: 1rem 0;
  display: grid;
  gap: 0.8rem;
}

.checkboxGroup label {
  display: flex;
  align-items: center;
  padding: 0.6rem;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.checkboxGroup label:hover {
  background: #f8f9fa;
  border-color: #381373;
}

.checkboxGroup input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #381373;
  border-radius: 4px;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.checkboxGroup input[type="checkbox"]:checked {
  background-color: #381373;
}

.checkboxGroup input[type="checkbox"]:checked::after {
  content: "✓";
  position: absolute;
  color: white;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Estilização refinada dos radio buttons */
.radioGroup {
  margin: 1rem 0;
  display: grid;
  gap: 0.8rem;
}

.radioGroup label {
  display: flex;
  align-items: center;
  padding: 0.6rem;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.radioGroup label:hover {
  background: #f8f9fa;
  border-color: #381373;
}

.radioGroup input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #381373;
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.radioGroup input[type="radio"]:checked {
  border-width: 6px;
}

/* Estilização do input de texto */
.input {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #fff;
}

.input:focus {
  border-color: #381373;
  box-shadow: 0 0 0 4px rgba(56, 19, 115, 0.1);
  outline: none;
}

/* Estilização do textarea */
.textarea {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 1rem;
  resize: vertical;
  transition: all 0.3s ease;
  background-color: #fff;
  line-height: 1.5;
}

.textarea:focus {
  border-color: #381373;
  box-shadow: 0 0 0 4px rgba(56, 19, 115, 0.1);
  outline: none;
}

/* Títulos das seções */
.sectionTitle {
  background-color: #1D0835;
  color: #ffffff;
  padding: 1rem 1.5rem;
  margin: 2.5rem -2rem;
  font-size: 1.25rem;
  font-weight: 500;
  position: relative;
  border-radius: 4px;
}

.sectionTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  width: 50px;
  height: 3px;
  background-color: #f5671f;
}

.uploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.uploadLabel {
  display: block;
  cursor: pointer;
}

.uploadBox {
  width: 300px;
  height: 200px;
  background-color: #4728e2; /* Cor de fundo parecida com a da imagem */
  color: #ffffff;
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.uploadBox i {
  font-size: 2rem;
  margin-bottom: 0.2rem;
}

.uploadBox p {
  font-size: 1.1rem;
  margin: 0.3rem 0;
}

.uploadBox span {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.browseButton {
  background-color: #ffffff;
  color: #4a3d8f;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.browseButton:hover {
  background-color: #e0e0e0;
}

.fileInput {
  display: none; /* Esconde o input de arquivo real */
}

/* Botões */
.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  gap: 1rem;
}

.buttonBack,
.buttonNext {
  padding: 0.9rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.3s ease;
}

.buttonBack {
  background-color: #381373;
  box-shadow: 0 2px 4px rgba(56, 19, 115, 0.2);
}

.buttonBack:hover {
  background-color: #2a0b52;
  transform: translateY(-2px);
}

.buttonNext {
  background-color: #f5671f;
  box-shadow: 0 2px 4px rgba(245, 103, 31, 0.2);
}

.buttonNext:hover {
  background-color: #d0541b;
  transform: translateY(-2px);
}

/* Responsividade */
@media (max-width: 768px) {
  .formContainer {
    margin: 1rem;
    padding: 1rem;
  }
  
  .sectionTitle {
    margin: 1.5rem -1rem;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
  }
  
  .buttonGroup {
    flex-direction: column;
  }
  
  .buttonBack,
  .buttonNext {
    width: 100%;
  }
}