/* Navbar.module.css */
:root {
  --primary-color: #381373;
  --secondary-color: #f5671f;
  --text-color: #333;
  --bg-color: #fff;
}

.navbar {
  background: var(--primary-color);
  color: var(--bg-color);
  padding: 1rem 0;
  border-bottom: var(--secondary-color) 3px solid;
  position: fixed; /* Mudado para fixed */
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000; 
}

.navbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo {
  margin-left: 1rem;
}

.logo img {
  height: 50px;
}

.toggleButton {
  display: none;
  background: none;
  border: none;
  color: var(--bg-color);
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1001;
}

.navLinks {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navLinks li {
  margin: 1rem;
  position: relative;
}

.navLinks li a {
  color: var(--bg-color);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
  position: relative;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.navLinks li a:hover,
.navLinks li.current a {
  color: var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
}

.registar {
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--bg-color);
  background-color: var(--secondary-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.registar:hover {
  background-color: #d85616;
  transform: scale(1.05);
}

/* Adicione esse espaçador para compensar a navbar fixa */
:global(body) {
  padding-top: 80px; /* Ajuste este valor de acordo com a altura da sua navbar */
}

@media (max-width: 768px) {
  .toggleButton {
    display: block;
  }

  .navLinks {
    display: none;
    flex-direction: column;
    position: fixed; /* Mudado para fixed */
    top: 76px; /* Altura da navbar + padding */
    left: 0;
    right: 0;
    background: var(--primary-color);
    padding: 1rem;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
    max-height: calc(100vh - 76px); /* Limita a altura ao restante da tela */
    overflow-y: auto; /* Adiciona scroll se necessário */
  }

  .navLinks.active {
    display: flex;
  }

  .navLinks li {
    margin: 0.5rem 0;
  }

  .navLinks li a {
    display: block;
    padding: 0.5rem 0;
  }

  .registar {
    margin-top: 0.5rem;
    width: 100%;
  }
}