* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html, body, #root {
  font-family: 'Open Sans', sans-serif;
  background-color: #f7f4f4;
  color: #333;
  line-height: 1.6;
  height: 100%;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}