.vagasContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Georgia', serif;
    list-style: none;
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }

  .link {
    list-style: none;
    list-style-type: none;
  }
  
  .titulo {
    color: #f5671f; 
    font-size: 2.7rem;
    margin-bottom: 25px;
    position: relative;
    font-weight: 700;
    list-style: none;
    cursor: pointer;
    text-align: center; 
    text-decoration: none;
  }
  
  .titulo:hover{
  
    transition: 0.6s;
    color: #4B0082;
  }
  
  .listaVagas {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .vagaItem {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #f5671f; 
  }
  
  .ultimoItem {
    border-bottom: none; 
  }
  
  .tituloVaga {
    font-weight: bold;
    color: #333;
    font-size: 1.3rem; 
    transition: color 0.3s;
  }
  
  .tituloVaga:hover {
    color: #f5671f; 
  }
  
  .detalhesVaga {
    color: #666;
    font-size: 0.9rem;
    font-family: 'Robbot', sans-serif; 
  }
  
  @media (max-width: 600px) {
    .vagaItem {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .detalhesVaga {
      margin-top: 5px;
    }
  }
  