:root {
  --primary-color: #381373;
  --secondary-color: #f5671f;
  --text-color: #333;
  --bg-color: #fff;
  --border-radius: 8px;
  --transition-speed: 0.3s;
}

/* SlideScrol.module.css */
.sliderContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    margin: 0;
    overflow: hidden;
  }
  
  .navButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--bg-color);
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
    z-index: 10;
  }
  
  .navButton:hover {
    background-color: var(--secondary-color);
  }
  
  .prevButton {
    left: 10px;
  }
  
  .nextButton {
    right: 10px;
  }
  
  .imageContainer {
    width: 100%;
    height: 0;
    padding-bottom: 30%;
    position: relative;
  }
  
  .imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }
  
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: rgba(245, 103, 31, 0.6); 
    color: var(--bg-color);
    padding: 12px 28px;
    text-decoration: none;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease, background-color 0.7s ease, color 0.7s ease;
    backdrop-filter: blur(5px); 
  }
  
  .btn:hover {
    background-color: rgba(56, 19, 115, 0.8); 
    color: var(--bg-color);
    transform: translate(-50%, -50%) scale(1.05);
  }
  
  .imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.7s ease-in-out, filter 0.7s ease-in-out;
  }
  
  .imageContainer:hover img {
    filter: blur(5px); 
  }
  
  .imageContainer:hover .btn {
    background-color: rgba(56, 19, 115, 0.8); 
  }

/* Modal Styles Melhorados */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-in-out;
}

.modalContent {
  background-color: var(--bg-color);
  padding: 1.5rem; /* Diminua o padding */
  border-radius: var(--border-radius);
  width: 90%;
  max-width: 600px;
  max-height: 80vh; /* Adicione esta linha para limitar a altura máxima */
  overflow-y: auto; /* Adicione esta linha para rolagem quando necessário */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: all var(--transition-speed) ease-out;
}


.modalVisible {
  opacity: 1;
  transform: translateY(0);
}

.modalHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.modalHeader h2 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.modalHeader p {
  color: #666;
  font-size: 1rem;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform var(--transition-speed);
}

.closeIcon {
  font-size: 1.8rem;
  color: var(--secondary-color);
  transition: color var(--transition-speed);
}

.closeButton:hover .closeIcon {
  color: var(--primary-color);
  transform: rotate(90deg);
}

.form_agendamento {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form_agendamento label {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 0.9rem;
}

.form_agendamento input,
.form_agendamento select,
.form_agendamento textarea {
 
  /* Limite de largura */
  height: 2.2rem; /* Altura ajustada */
  padding: 0.4rem; /* Padding reduzido para inputs mais compactos */
  font-size: 0.85rem; /* Reduz um pouco o tamanho da fonte */
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  box-sizing: border-box;
}

.form_agendamento input:focus,
.form_agendamento select:focus,
.form_agendamento textarea:focus {
  outline: none;
  border-color: var(--secondary-color);
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.checkboxLabel input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: var(--secondary-color);
}

.submitButton {
  background-color: var(--secondary-color);
  color: white;
  padding: 1rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition-speed);
  margin-top: 1rem;
}

.submitButton:hover {
  background-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  

  @media (max-width: 768px) {
    .navButton {
      font-size: 1.5rem;
      padding: 0.3rem;
    }

    .modalContent {
      padding: 1.5rem;
    }
  
    .formRow {
      grid-template-columns: 1fr;
    }
  
    .modalHeader h2 {
      font-size: 1.5rem;
    }
  
    .btn {
        padding: 10px 24px;
        font-size: 0.9rem;
      }
  }
  
  @media (max-width: 480px) {
    .navButton {
      font-size: 1rem;
    }
  
    .btn {
        padding: 8px 20px;
        font-size: 0.8rem;
    }

    .modalContent {
      padding: 1rem;
    }
  
    .form_agendamento {
      gap: 1rem;
    }
  
    .modalHeader {
      margin-bottom: 1.5rem;
    }

    .form_agendamento input,
  .form_agendamento select,
  .form_agendamento textarea {
    font-size: 0.9rem;
  }

  .closeButton {
    font-size: 1.2rem;
  }
  }