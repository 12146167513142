:root {
    --primary-color: #381373;
    --secondary-color: #f5671f;
    --text-color: #333;
    --bg-color: #fff;
    --border-radius: 8px;
    --transition-speed: 0.3s;
  }

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--bg-color);
    color: var(--text-color);
  }
  
  .jobHeader {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-bottom: 3rem;
  }
  
  .jobContent {
    flex: 1;
  }
  
  .jobTitle {
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    position: relative;
  }
  
  .jobTitle::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius);
  }
  
  .jobDescription {
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text-color);
  }
  
  .imageContainer {
    flex: 0 0 400px;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .imageContainer img {
    
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .sectionTitle {
    color: var(--primary-color);
    font-size: 1.8rem;
    margin: 2rem 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--secondary-color);
  }
  
  .requirementsList,
  .responsibilitiesList,
  .offerList {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
  }
  
  .requirementsList li,
  .responsibilitiesList li,
  .offerList li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  
  .requirementsList li::before,
  .responsibilitiesList li::before,
  .offerList li::before {
    content: '•';
    color: var(--secondary-color);
    position: absolute;
    left: 0;
    font-weight: bold;
  }
  
  .requirementsSection,
  .responsibilitiesSection,
  .offerSection {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: var(--border-radius);
    margin-bottom: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform var(--transition-speed);
  }
  
  .requirementsSection:hover,
  .responsibilitiesSection:hover,
  .offerSection:hover {
    transform: translateY(-5px);
  }
  
  .applySection {
    text-align: center;
    margin: 3rem 0;
  }
  
  .applyButton {
    background-color: var(--secondary-color);
    color: white;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-speed),
                transform var(--transition-speed);
  }
  
  .applyButton:hover {
    background-color: #e05618;
    transform: translateY(-2px);
  }
  
  /* Responsividade */
  @media (max-width: 1024px) {
    .jobHeader {
      flex-direction: column;
    }
  
    .imageContainer {
      flex: 0 0 auto;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .jobTitle {
      font-size: 2rem;
    }
  
    .requirementsSection,
    .responsibilitiesSection,
    .offerSection {
      padding: 1.5rem;
    }
  
    .sectionTitle {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .jobTitle {
      font-size: 1.8rem;
    }
  
    .jobDescription {
      font-size: 1rem;
    }
  
    .applyButton {
      width: 100%;
      padding: 0.8rem;
    }
  }